import validate from 'validate.js';
import * as constraints from './validationConstraints';
import { isArray } from 'util';
import PhoneNumber from 'awesome-phonenumber';

const isProd = process.env.NODE_ENV === 'production';

export const validateLogin = formValues => {
  const errors = validate({ ...formValues }, constraints.login);
  return errors;
};

export const validateSignupForm = formValues => {
  const errors = validate({ ...formValues }, constraints.signup);
  return errors;
};

export const validateEmail = email => {
  if (isProd) {
    const dmRegex = /^[a-zA-Z0-9_.+-]+@(digitalmakers|dmkrs)\.io$/gi;
    const regTest = new RegExp(dmRegex);
    const testing = regTest.test(email);

    if (!testing) {
      // check for the presence of a + in the email indicating an alias
      const plusIdx = email.indexOf('+');
      if (plusIdx > -1) {
        return { email: ['Email aliases are not allowed.'] };
      }
    }
  }

  const error = validate({ email }, constraints.email);
  return error;
};

export const validateEmailFlat = email => {
  const error = validate({ email }, constraints.email, { format: "flat" });
  return error;
};

export const validatePassword = password => {
  const error = validate({ password }, constraints.password);
  return error;
};

export const validateConfirmPassword = (password, confirm) => {
  const error = validate({ password, confirm }, constraints.confirmPassword);
  return error;
};

export const validateFom = fom => {
  if (fom) {
    const error = validate({ fom }, constraints.fom);
    return error;
  }
  return null;
};

// the guts of this function are mostly the same as in the mobile app repo
// in src/lib/validation.js
// please keep them in sync if you change this.
export const validateMobile = (number, country, countryCode) => {
  console.log(`validateMobile => ${number}, ${countryCode}`)
  if (!countryCode) {
    countryCode = 'AU';
  }
  const error = validate.single(number, constraints.signup.mobileNumber);
  if (number.trim().length === 0) {
    return [`Please enter a valid ${countryCode} mobile number.`]; // fullstop is intentionally left out
  }

  const parsedNumber = new PhoneNumber(number, countryCode);
  if (!parsedNumber.isValid()) {
    return [`Please enter a valid ${countryCode} mobile number.`];
  }

  // flat out only allow AU or NZ * mobiles *.
  // note: parsedNumber.isMobile() isn't specific enough for the business logic.
  // console.log(parsedNumber.getNumber('international'));
  if (
    !parsedNumber
      .getNumber('international') // will return the full number, with spaces
      .replace(/[^\d+]+/g, '')
      .match(/^\+(642|614)/)
  ) {
    return [`Please enter a valid ${countryCode} mobile number.`];
  }

  return error;
}

export const returnFirstError = errorArray => {
  if (errorArray) {
    if (errorArray.length > 1 && isArray(errorArray)) return errorArray[0]
  }
  return errorArray;
};

export const validateEntry = (type, value) => {
  // validate the type 
  const constraint = constraints.signup[type];
  const error = validate.single(value, constraint);
  return error;
}